import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonButtons, IonBackButton } from '@ionic/react';


const Payroll: React.FC = () => {
  return (
    <IonPage>
 <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Drive/payroll" />
          </IonButtons>
          <IonTitle>Payroll Contacts</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
                <IonCard style={{backgroundColor: "#414141", color: "white"}} >
                  <IonCardHeader>
                    <IonCardTitle>Contacts</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    Driver Payroll: <a href="tel:615-777-1682">(615) 777-1682</a>
                    <br />
                    <br />
                    Please contact your driver manager for information.
                  </IonCardContent>
                </IonCard>

      </IonContent>
    </IonPage>
  );
};

export default Payroll;
